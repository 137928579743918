define("ember-time-field/states/hours-focused", ["exports", "ember-states/state", "ember-time-field/utils/codes"], function (exports, _emberStatesState, _emberTimeFieldUtilsCodes) {
  "use strict";

  exports["default"] = _emberStatesState["default"].create({
    initialState: "digit1",

    digit1: _emberStatesState["default"].create({
      key: function key(manager, code) {
        if (!(0, _emberTimeFieldUtilsCodes.isNumberCode)(code)) {
          return; // no-op
        }

        var num = (0, _emberTimeFieldUtilsCodes.keyCodeToNumber)(code);
        manager.get("input").setHours(num);

        if (num <= 2) {
          manager.transitionTo("digit2");
        } else {
          manager.transitionTo("minutes");
        }
      }
    }),

    digit2: _emberStatesState["default"].create({
      key: function key(manager, code) {
        if (!(0, _emberTimeFieldUtilsCodes.isNumberCode)(code)) {
          return; // no-op
        }

        var num = (0, _emberTimeFieldUtilsCodes.keyCodeToNumber)(code);
        manager.get("input").setHoursDigit2(num);
        manager.transitionTo("minutes");
      }
    }),

    enter: function enter(manager) {
      this.focusIn(manager);
    },

    focusIn: function focusIn(manager) {
      manager.get("input").selectHours();
    },

    right: function right(manager) {
      manager.transitionTo("minutes");
    },

    up: function up(manager) {
      manager.get("input").incrementHours();
    },

    down: function down(manager) {
      manager.get("input").decrementHours();
    }

  });
});