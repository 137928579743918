define('ember-window-resize/initializers/window-resize', ['exports', 'ember-window-resize/services/window-resize'], function (exports, _emberWindowResizeServicesWindowResize) {
  'use strict';

  exports.initialize = initialize;

  function initialize(application) {
    application.register('service:windowResize', _emberWindowResizeServicesWindowResize['default']);
    application.inject('route', 'windowResize', 'service:windowResize');
    application.inject('controller', 'windowResize', 'service:windowResize');
    application.inject('component', 'windowResize', 'service:windowResize');
  }

  exports['default'] = {
    initialize: initialize
  };
});