define('ember-google-charts/services/google-charts', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Service.extend({
    googlePackages: ['corechart', 'bar', 'line', 'scatter'],
    language: 'en',

    _callbacksAddedWhileLoading: [],
    _loadComplete: false,
    _loadInProgress: false,

    loadPackages: function loadPackages() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var google = window.google;

        var wasPreviouslyLoadedInTestSuite = google && google.visualization;

        if (_this.get('_loadComplete') || wasPreviouslyLoadedInTestSuite) {

          /* If Google charts has been loaded, new calls
          to loadPackages can be resolved immediately */

          resolve();
        } else if (_this.get('_loadInProgress')) {

          /* If this promise is created whilst google charts
          is being loaded, we can't resolve until it is loaded.
          Thus, we keep track of the resolve callbacks passed. */

          _this.get('_callbacksAddedWhileLoading').push([resolve, reject]);
        } else {
          _this.set('_loadInProgress', true);

          google.charts.load('current', {
            language: _this.get('language'),
            packages: _this.get('googlePackages'),

            callback: function callback() {

              /* Check for a corner case where the service has
              been destroyed before the charts are finished
              loading. If we set a property on a destroyed
              service, Ember throws an error. */

              if (_this.isDestroying || _this.isDestroyed) {
                reject();

                _this.get('_callbacksAddedWhileLoading').forEach(function (resolveCallback) {
                  resolveCallback[1]();
                });

                return;
              }

              /* Once Google Charts has been loaded, mark the
              library as loaded and call all resolve callbacks
              passed to this promise before the Google Charts
              library had completed loading */

              _this.set('_loadComplete', true);

              resolve();

              _this.get('_callbacksAddedWhileLoading').forEach(function (resolveCallback) {
                resolveCallback[0]();
              });
            }

          });
        }
      });
    }

  });
});