define('ember-responsive/initializers/responsive', ['exports'], function (exports) {
  /**
   * Ember responsive initializer
   *
   * Supports auto injecting media service app-wide.
   * Generated by the ember-responsive addon.
   */
  'use strict';

  exports.initialize = initialize;

  function initialize(application) {
    application.registerOptionsForType('breakpoints', { instantiate: false });
  }

  exports['default'] = {
    name: 'ember-responsive-breakpoints',
    initialize: initialize
  };
});