define('ember-g-recaptcha/components/g-recaptcha', ['exports', 'ember', 'ember-g-recaptcha/configuration'], function (exports, _ember, _emberGRecaptchaConfiguration) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({

    classNames: ['g-recaptcha'],

    sitekey: _emberGRecaptchaConfiguration['default'].siteKey,

    tabindex: _ember['default'].computed.alias('tabIndex'),

    renderReCaptcha: function renderReCaptcha() {
      var _this = this;

      if (_ember['default'].isNone(window.grecaptcha)) {
        _ember['default'].run.later(function () {
          _this.renderReCaptcha();
        }, 500);
      } else {
        var container = this.$()[0];
        var properties = this.getProperties('sitekey', 'theme', 'type', 'size', 'tabindex');
        var parameters = _ember['default'].merge(properties, {
          callback: this.get('successCallback').bind(this),
          'expired-callback': this.get('expiredCallback').bind(this)
        });
        var widgetId = window.grecaptcha.render(container, parameters);
        this.set('widgetId', widgetId);
        this.set('ref', this);
      }
    },

    resetReCaptcha: function resetReCaptcha() {
      if (_ember['default'].isPresent(this.get('widgetId'))) {
        window.grecaptcha.reset(this.get('widgetId'));
      }
    },

    successCallback: function successCallback(reCaptchaResponse) {
      var action = this.get('onSuccess');
      if (_ember['default'].isPresent(action)) {
        action(reCaptchaResponse);
      }
    },

    expiredCallback: function expiredCallback() {
      var action = this.get('onExpired');
      if (_ember['default'].isPresent(action)) {
        action();
      } else {
        this.resetReCaptcha();
      }
    },

    // Lifecycle Hooks

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      _ember['default'].run.next(function () {
        _this2.renderReCaptcha();
      });
    }

  });
});