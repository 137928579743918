define('ember-cli-pickadate/components/picker', ['exports', 'ember', 'ember-get-config'], function (exports, _ember, _emberGetConfig) {
  'use strict';

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }return target;
  };

  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;
  var isEmpty = _ember['default'].isEmpty;

  exports['default'] = Component.extend({
    tagName: 'input',
    attributeBindings: ['placeholder', 'disabled', 'type', 'name'],
    name: null,
    disabled: null,
    type: 'text',
    date: null,
    picker: null,

    didRender: function didRender() {
      this.updateInputText();
      this.toggleInputDisabled();
    },

    getOptions: function getOptions() {
      var defaults = {
        'date': {},
        'time': {}
      };
      var appOptions = _emberGetConfig['default']['ember-cli-pickadate'];
      return _extends({}, defaults, appOptions);
    },

    toggleInputDisabled: function toggleInputDisabled() {
      if (this.get('disabled')) {
        this.get('picker').stop();
        this.$().prop('disabled', true); // pick-a-date is doing funny things with the disabled attribute
      } else {
          this.get('picker').start();
          this.$().prop('disabled', false);
        }
    },

    dateChanged: observer('date', function () {
      this.updateInputText();
    }),

    optionsChanged: observer('options', function () {
      var options = this.get('options');

      if (isEmpty(options)) {
        // TODO: unset options which were removed
        return;
      }

      for (var key in options) {
        if (options.hasOwnProperty(key)) {
          this.get('picker').set(key, options[key]);
        }
      }
    }),

    onClose: function onClose() {
      // Prevent pickadate from re-opening on focus
      _ember['default'].$(document.activeElement).blur();
    },

    willDestroyElement: function willDestroyElement() {
      var picker = this.get('picker');
      if (picker && picker.get('start')) {
        picker.stop();
      }
    }
  });
});