define('ember-window-resize/services/window-resize', ['exports'], function (exports) {
  'use strict';

  exports['default'] = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this._onResize = function (event) {
        _this._resizeHandler(event);
      };
      this._initialiseResizeListener();
    },
    destroy: function destroy() {
      this._super.apply(this, arguments);
      this._destroyResizeListener();
    },
    _initialiseResizeListener: function _initialiseResizeListener() {
      window.addEventListener('resize', this._onResize);
    },
    _destroyResizeListener: function _destroyResizeListener() {
      window.removeEventListener('resize', this._onResize);
    },
    _resizeHandler: function _resizeHandler(event) {
      this.trigger('didResize', event);
    }
  });
});