define('ember-cli-notifications/components/notification-message', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['c-notification'],
    classNameBindings: ['processedType', 'notification.dismiss::c-notification--in', 'autoClear::c-notification--dismissable'],

    // Set icon depending on notification type
    notificationIcon: _ember['default'].computed('notification.type', 'icons', function () {
      var icons = this.get('icons');

      if (icons === 'font-awesome') {
        switch (this.get('notification.type')) {
          case "info":
            return 'fa fa-info-circle';
          case "success":
            return 'fa fa-check';
          case "warning":
            return 'fa fa-warning';
          case "error":
            return 'fa fa-exclamation-circle';
        }
      }

      if (icons === 'bootstrap') {
        switch (this.get('notification.type')) {
          case "info":
            return 'glyphicon glyphicon-info-sign';
          case "success":
            return 'glyphicon glyphicon-ok-sign';
          case "warning":
          case "error":
            return 'glyphicon glyphicon-exclamation-sign';
        }
      }
    }),

    processedType: _ember['default'].computed('notification.type', function () {
      if (this.get('notification.type') && _ember['default'].A(['info', 'success', 'warning', 'error']).contains(this.get('notification.type'))) {
        return "c-notification--" + this.get('notification.type');
      }
    }),

    // Apply the clear animation duration rule inline
    notificationClearDuration: _ember['default'].computed('notification.clearDuration', function () {
      var duration = _ember['default'].Handlebars.Utils.escapeExpression(this.get('notification.clearDuration'));
      return _ember['default'].String.htmlSafe('animation-duration: ' + duration + 'ms; -webkit-animation-duration: ' + duration + 'ms');
    }),

    actions: {
      removeNotification: function removeNotification() {
        this.notifications.removeNotification(this.get('notification'));
      }
    }
  });
});