define('ember-x-tabs/components/x-tab/pane', ['exports', 'ember-x-tabs/templates/components/x-tab/pane', 'ember-x-tabs/mixins/component-child'], function (exports, _emberXTabsTemplatesComponentsXTabPane, _emberXTabsMixinsComponentChild) {
    'use strict';

    var computed = Ember.computed,
        observer = Ember.observer,
        scheduleOnce = Ember.run.scheduleOnce;

    exports['default'] = Ember.Component.extend(_emberXTabsMixinsComponentChild['default'], {
        layout: _emberXTabsTemplatesComponentsXTabPane['default'],
        classNameBindings: ['contentCurrent'],

        tagName: 'section',

        activeId: null,

        isActive: computed('activeId', 'elementId', function () {
            return this.get('activeId') === this.get('elementId');
        }).readOnly(),

        contentCurrent: true,

        show: function show() {
            this.set('contentCurrent', true);
        },
        hide: function hide() {
            this.set('contentCurrent', false);
        },

        _showHide: observer('isActive', function () {
            if (this.get('isActive')) {
                this.show();
            } else {
                this.hide();
            }
        }),

        init: function init() {
            this._super.apply(this, arguments);

            scheduleOnce('afterRender', this, function () {
                this.set('contentCurrent', this.get('isActive'));
            });
        }
    });
});