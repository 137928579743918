define('ember-google-map/mixins/google-object', ['exports', 'ember', 'ember-google-map/core/google-object-property', 'ember-google-map/core/google-object-event'], function (exports, _ember, _emberGoogleMapCoreGoogleObjectProperty, _emberGoogleMapCoreGoogleObjectEvent) {
  'use strict';

  var computed = _ember['default'].computed;
  var get$ = _ember['default'].get;
  var fmt = _ember['default'].String.fmt;
  var dasherize = _ember['default'].String.dasherize;
  var forEach = _ember['default'].EnumerableUtils.forEach;

  /**
   * @extension GoogleObjectMixin
   * @mixin GoogleObjectMixin
   */
  var GoogleObjectMixin = _ember['default'].Mixin.create({
    /**
     * The fully qualified class name of the object
     * @property googleFQCN
     * @type {string}
     */
    googleFQCN: null,

    /**
     * The class of this object
     * @property googleClass
     * @type {subclass of google.maps.MVCObject}
     */
    googleClass: computed('googleFQCN', {
      get: function get() {
        var path = this.get('googleFQCN');
        if (path) {
          return get$(window, path);
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * Name/label of the object for debug
     * @property googleName
     * @type {string}
     */
    googleName: computed('googleFQCN', {
      get: function get() {
        var name = this.get('googleFQCN');
        return name ? dasherize(name.split('.').pop()) : this.toString();
      },
      set: function set(key, value) {
        return value;
      }
    }),

    /**
     * The definition of all google properties to bind
     * @property googleProperties
     * @type Object
     */
    googleProperties: null,

    /**
     * The definition of all google events to bind
     * @property googleEvents
     * @type Object
     */
    googleEvents: computed.oneWay('controller.googleEvents'),

    /**
     * The default target for our actions
     * @property googleEventsTarget
     * @type {Ember.Object}
     */
    googleEventsTarget: computed.oneWay('targetObject'),

    /**
     * The google object itself
     * @property googleObject
     * @type google.maps.MVCObject
     */
    googleObject: computed({
      get: function get() {
        return null;
      },
      set: function set(key, value) {
        this.unlinkGoogleObject();
        this.linkGoogleObject(value);
        return value;
      }
    }),

    /**
     * Creates the google object
     *
     * @method createGoogleObject
     * @param {*} [firstArg]
     * @param {Object} [optionsOverrides]
     * @return {google.maps.MVCObject}
     */
    createGoogleObject: function createGoogleObject(optionsOverrides) {
      var opt = this.serializeGoogleOptions(),
          object,
          firstArg,
          Class;
      if (arguments.length === 2) {
        firstArg = optionsOverrides;
        optionsOverrides = arguments[1];
      }
      opt = _ember['default'].merge(opt, optionsOverrides);
      _ember['default'].debug(fmt('[google-maps] creating new %@: %@', this.get('googleName'), opt));
      Class = this.get('googleClass');
      if (firstArg) {
        object = new Class(firstArg, opt);
      } else {
        object = new Class(opt);
      }
      this.set('googleObject', object);
      this.synchronizeEmberObject();
      return object;
    },

    /**
     * An array of all compiled (parsed) properties
     * @property _compiledProperties
     * @type Array.<GoogleObjectProperty>
     * @private
     */
    _compiledProperties: computed({
      get: function get() {
        var def = this.get('googleProperties') || {},
            res = [],
            d,
            defined = Object.create(null);
        for (var k in def) {
          if (def.hasOwnProperty(k)) {
            d = def[k];
            if (typeof d === 'string') {
              d = { name: d };
            } else if (d === true) {
              d = {};
            }
            res.push(d = new _emberGoogleMapCoreGoogleObjectProperty['default'](k, d));
            defined[d.getName()] = null;
            d = null;
          }
        }
        // now read all properties of the object which name start with 'gopt_'
        def = Object.keys(this);
        for (var i = 0; i < def.length; i++) {
          if (/^gopt_/.test(def[i]) && (k = def[i].substr(5)) && !(k in defined)) {
            res.push(new _emberGoogleMapCoreGoogleObjectProperty['default'](def[i], { name: k, optionOnly: true }));
          }
        }
        return _ember['default'].A(res);
      }
    }),

    /**
     * An array of all compiled (parsed) events
     * @property _compiledEvents
     * @type Array.<GoogleObjectEvent>
     * @private
     */
    _compiledEvents: computed({
      get: function get() {
        var def, k, res, d, defaultTarget;
        def = this.get('googleEvents') || {};
        res = [];
        defaultTarget = this.get('googleEventsTarget');

        // first add our core events
        forEach(this.get('_coreGoogleEvents') || [], function (name) {
          res.push(new _emberGoogleMapCoreGoogleObjectEvent['default'](name, {
            target: this,
            method: '_handleCoreEvent',
            prepend: true
          }));
        });

        // then add user defined events
        for (k in def) {
          if (def.hasOwnProperty(k)) {
            d = def[k];
            if (typeof d === 'string') {
              d = { action: d };
            } else if (d === true) {
              d = {};
            }
            if (!d.target && defaultTarget) {
              d.target = defaultTarget;
            }
            res.push(new _emberGoogleMapCoreGoogleObjectEvent['default'](k, d));
            d = null;
          }
        }

        // finally add all overwritten events (`ev_xyz` properties)
        forEach(Object.keys(this), function (key) {
          var d, matches, action;
          if ((matches = key.match(/^ev_(.+)$/)) && (action = this.get(key))) {
            d = { action: this.get(key) };
            if (defaultTarget) {
              d.target = defaultTarget;
            }
            res.push(new _emberGoogleMapCoreGoogleObjectEvent['default'](matches[1], d));
          }
        }, this);

        return _ember['default'].A(res);
      }
    }),

    /**
     * Handle a core event
     *
     * @method _handleCoreEvent
     * @param {string} name
     */
    _handleCoreEvent: function _handleCoreEvent(name) {
      _ember['default'].debug(fmt('[google-map] Unhandled core event `%@` triggered on `%@`', name, this.get('googleName')));
    },

    /**
     * Serialize all google options into an object usable with google object constructor
     *
     * @method serializeGoogleOptions
     * @returns {Object}
     */
    serializeGoogleOptions: function serializeGoogleOptions() {
      var res = {},
          def = this.get('_compiledProperties');
      for (var i = 0; i < def.length; i++) {
        def[i].toOptions(this, res);
      }
      return res;
    },

    /**
     * Synchronize this Ember object by reading all values of the properties from google object
     */
    synchronizeEmberObject: function synchronizeEmberObject() {
      var def = this.get('_compiledProperties'),
          go = this.get('googleObject');
      if (!go) {
        return;
      }
      this.beginPropertyChanges();
      for (var i = 0; i < def.length; i++) {
        if (!def[i]._cfg.readOnly) {
          this.setProperties(def[i].readGoogle(go));
        }
      }
      this.endPropertyChanges();
    },

    /**
     * Unlink the google object
     */
    unlinkGoogleObject: function unlinkGoogleObject() {
      if (this.cacheFor('googleObject')) {
        this.get('_compiledEvents').invoke('unlink');
        this.get('_compiledProperties').invoke('unlink');
      }
    },

    /**
     * Link the google object to this object
     */
    linkGoogleObject: function linkGoogleObject(obj) {
      if (obj) {
        this.get('_compiledProperties').invoke('link', this, obj);
        this.get('_compiledEvents').invoke('link', this, obj);
      }
    },

    /**
     * Destroy our object, removing all listeners and pointers to google's object
     */
    destroyGoogleObject: _ember['default'].on('destroy', function () {
      this.set('googleObject', null);
      this.get('_compiledEvents').clear();
      this.get('_compiledProperties').clear();
    })
  });

  exports['default'] = GoogleObjectMixin;
});