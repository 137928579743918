define('ember-cli-tooltipster/components/tool-tipster', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    exports['default'] = _ember['default'].Component.extend({

        classNameBindings: ['tooltip'],

        attributeBindings: ['title'],

        updateTitle: _ember['default'].observer('title', function () {
            this.$().tooltipster('content', this.get('title'));
        }),

        updateContent: _ember['default'].observer('content', function () {
            this.$().tooltipster('content', this.get('content'));
        }),

        /**
         * Set how tooltips should be activated and closed.
         * Default: 'hover'
         * Options: [hover, click]
         * @type {String}
         */
        triggerEvent: 'hover',

        tooltipsterOptions: ['animation', 'arrow', 'arrowColor', 'content', 'contentAsHTML', 'debug', 'delay', 'minWidth', 'maxWidth', 'offsetX', 'offsetY', 'position', 'positionTracker', 'speed', 'timer', 'theme', 'updateAnimation'],

        _initializeTooltipster: (function () {
            var _this = this;
            var options = {};

            _this.get('tooltipsterOptions').forEach(function (item) {
                if (!_ember['default'].isEmpty(_this.get(item))) {
                    options[item] = _this.get(item);
                }
            });

            options.trigger = _this.get('triggerEvent');
            options.functionInit = _ember['default'].$.proxy(this.functionInit, this);
            options.functionBefore = _ember['default'].$.proxy(this.functionBefore, this);
            options.functionReady = _ember['default'].$.proxy(this.functionReady, this);
            options.functionAfter = _ember['default'].$.proxy(this.functionAfter, this);
            options.positionTrackerCallback = _ember['default'].$.proxy(this.positionTrackerCallback, this);

            this.$().tooltipster(options);
        }).on('didInsertElement'),

        _destroyTooltipster: (function () {
            this.$().tooltipster('destroy');
        }).on('willDestroyElement')
    });
});