define('ember-data-change-tracker/model-ext', ['exports', 'ember-data/model', 'ember-data-change-tracker/tracker'], function (exports, _emberDataModel, _emberDataChangeTrackerTracker) {
  'use strict';

  _emberDataModel['default'].reopen({

    /**
     * Did an attribute/association change?
     *
     * @param {String} key the attribute/association name
     * @param {Object} changed optional ember-data changedAttribute object
     * @returns {Boolean} true if value changed
     */
    didChange: function didChange(key, changed, options) {
      return _emberDataChangeTrackerTracker['default'].didChange(this, key, changed, options);
    },

    /**
     * Did any attribute/association change?
     *
     * returns object with:
     *  {key: value} = {attribute: true}
     *
     * If the the attribute changed, it will be included in this object
     *
     * @returns {*}
     */
    modelChanges: function modelChanges() {
      var changed = Ember.assign({}, this.changedAttributes());
      var trackerInfo = _emberDataChangeTrackerTracker['default'].metaInfo(this);
      for (var key in trackerInfo) {
        if (!changed[key] && trackerInfo.hasOwnProperty(key)) {
          if (this.didChange(key, changed)) {
            changed[key] = true;
          }
        }
      }
      return changed;
    },

    /**
     * Rollback all the changes on this model, for the keys you are
     * tracking.
     *
     * NOTE: Be sure you understand what keys you are tracking.
     * By default, tracker will save all keys, but if you set up
     * a model to 'only' track a limited set of keys, then the rollback
     * will only be limited to those keys
     *
     */
    rollback: function rollback() {
      var trackerInfo = _emberDataChangeTrackerTracker['default'].metaInfo(this);
      this.rollbackAttributes();
      var rollbackData = _emberDataChangeTrackerTracker['default'].rollbackData(this, trackerInfo);
      var normalized = _emberDataChangeTrackerTracker['default'].normalize(this, rollbackData);
      this.store.push(normalized);
    },

    // alias for saveChanges method
    startTrack: function startTrack() {
      this.saveChanges();
    },

    /**
     * Save the current state of the model
     *
     * NOTE: This is needed when manually pushing data
     * to the store and ussing Ember < 2.10
     *
     * options like => {except: 'company'}
     *
     * @param {Object} options
     */
    saveChanges: function saveChanges(options) {
      _emberDataChangeTrackerTracker['default'].setupTracking(this);
      _emberDataChangeTrackerTracker['default'].triggerIsDirtyReset(this);
      _emberDataChangeTrackerTracker['default'].saveChanges(this, options);
    },
    saveTrackerChanges: function saveTrackerChanges(options) {
      this.saveChanges(options);
    },

    /**
     * Get value of the last known value tracker is saving for this key
     *
     * @param {String} key attribute/association name
     * @returns {*}
     */
    savedTrackerValue: function savedTrackerValue(key) {
      return _emberDataChangeTrackerTracker['default'].lastValue(this, key);
    },

    // save state when model is loaded or created if using auto save
    setupTrackerMetaData: Ember.on('ready', function () {
      if (_emberDataChangeTrackerTracker['default'].isIsDirtyEnabled(this)) {
        // this is experimental
        _emberDataChangeTrackerTracker['default'].initializeDirtiness(this);
      }
      if (_emberDataChangeTrackerTracker['default'].isAutoSaveEnabled(this)) {
        _emberDataChangeTrackerTracker['default'].setupTracking(this);
        this.saveChanges();
      }
    }),

    // when model updates, update the tracked state if using auto save
    saveOnUpdate: Ember.on('didUpdate', function () {
      if (_emberDataChangeTrackerTracker['default'].isAutoSaveEnabled(this) || _emberDataChangeTrackerTracker['default'].isIsDirtyEnabled(this)) {
        this.saveChanges();
      }
    }),

    // There is no didReload callback on models, so have to override reload
    reload: function reload() {
      var _this = this;

      var promise = this._super();
      promise.then(function () {
        if (_emberDataChangeTrackerTracker['default'].isAutoSaveEnabled(_this)) {
          _this.saveChanges();
        }
      });
      return promise;
    },

    // when model deletes, remove any tracked state
    clearSavedAttributes: Ember.on('didDelete', function () {
      _emberDataChangeTrackerTracker['default'].clear(this);
    })

  });
});