define("ember-time-field/states/unfocused", ["exports", "ember-states/state"], function (exports, _emberStatesState) {
  "use strict";

  exports["default"] = _emberStatesState["default"].create({
    focusIn: function focusIn(manager) {
      manager.transitionTo("focused.hours");
    },
    refocus: function refocus() {
      // no-op
    }
  });
});