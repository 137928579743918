define('ember-x-tabs/mixins/component-child', ['exports'], function (exports) {
    'use strict';

    exports['default'] = Ember.Mixin.create({

        _didRegister: false,

        _registerWithParent: function _registerWithParent() {
            if (!this._didRegister) {
                var parent = this.get('parent');
                if (parent) {
                    parent.registerChild(this);
                    this._didRegister = true;
                }
            }
        },
        _unregisterFromParent: function _unregisterFromParent() {
            var parent = this.get('parent');
            if (this._didRegister && parent) {
                parent.removeChild(this);
                this._didRegister = false;
            }
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this._registerWithParent();
        },
        willRender: function willRender() {
            this._super.apply(this, arguments);
            this._registerWithParent();
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this._registerWithParent();
        }
    });
});