define('ember-time-field/states/focused', ['exports', 'ember-states/state', 'ember-time-field/states/hours-focused', 'ember-time-field/states/minutes-focused', 'ember-time-field/states/period-focused'], function (exports, _emberStatesState, _emberTimeFieldStatesHoursFocused, _emberTimeFieldStatesMinutesFocused, _emberTimeFieldStatesPeriodFocused) {
  'use strict';

  exports['default'] = _emberStatesState['default'].create({
    hours: _emberTimeFieldStatesHoursFocused['default'],
    minutes: _emberTimeFieldStatesMinutesFocused['default'],
    period: _emberTimeFieldStatesPeriodFocused['default'],

    focusOut: function focusOut(manager) {
      manager.transitionTo("unfocused");
    },

    refocus: function refocus(manager) {
      manager.send("focusIn");
    },

    left: function left() {
      // no-op if not handled by child
    },

    right: function right() {
      // no-op if not handled by child
    }
  });
});