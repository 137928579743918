define('ember-simple-auth-extended/initializers/setup-session', ['exports', 'ember', 'ember-simple-auth-extended/internal-session-employee', 'ember-simple-auth-extended/session-stores/ephemeral', 'ember-simple-auth-extended/utils/inject'], function (exports, _ember, _emberSimpleAuthExtendedInternalSessionEmployee, _emberSimpleAuthExtendedSessionStoresEphemeral, _emberSimpleAuthExtendedUtilsInject) {
  'use strict';

  exports['default'] = setupSession;

  function setupSession(registry, name) {
    registry.register('session-' + name + ':main', _emberSimpleAuthExtendedInternalSessionEmployee['default']);

    var store = 'session-store:' + name;
    if (_ember['default'].testing) {
      store = 'session-store:test';
      registry.register(store, _emberSimpleAuthExtendedSessionStoresEphemeral['default']);
    }
    (0, _emberSimpleAuthExtendedUtilsInject['default'])(registry, 'session-' + name + ':main', 'store', store);
  }
});