define('ember-cli-pickadate/components/pick-a-date', ['exports', 'ember', 'ember-cli-pickadate/components/picker'], function (exports, _ember, _emberCliPickadateComponentsPicker) {
  'use strict';

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }return target;
  };

  var Component = _ember['default'].Component;
  var isEmpty = _ember['default'].isEmpty;
  var observer = _ember['default'].observer;

  var DEFAULT_DATE_FORMAT = 'd mmmm, yyyy';

  /**
   * @public
   *
   * @param disabled - (boolean) disable the datepicker
   * @param placeholder - (string) the text to display in the input when nothing is selected
   * @param options - (object) options available via the pick-a-date API (http://amsul.ca/pickadate.js/)
   * @param date - (Date) the date to display
   * @param on-selected - (function) called when a date is selected and passed the new date as the first argument.
   */
  exports['default'] = _emberCliPickadateComponentsPicker['default'].extend({
    placeholder: "Select a date",
    classNames: ['ember-pick-a-date'],

    didInsertElement: function didInsertElement() {
      var _this = this;

      var defaults = this.getOptions().date;
      var options = _extends({}, defaults, this.attrs.options);
      options.onClose = options.onClose || this.onClose;
      options.onSet = function (ev) {
        _this.onSelected(ev);
      };

      for (var option in options.value) {
        options[option] = options.value[option];
      }

      this.$().pickadate(options);
      this.set('picker', this.$().pickadate('picker'));
    },

    updateInputText: function updateInputText() {
      var date = this.get('date');
      var options = this.attrs.options || {};
      var format = options.format || DEFAULT_DATE_FORMAT;
      var picker = this.get('picker');

      if (!picker.get('open')) {
        picker.set('select', date, {
          format: format,
          muted: true
        });
      }
    },

    onSelected: function onSelected(ev) {
      var date = this.get('date') || new Date();
      var picker = this.get('picker');
      var dateItem = picker.get('select');
      if (!dateItem) {
        if (this.attrs['on-selected']) {
          this.attrs['on-selected'](null, ev);
        }
        return;
      }

      var newDate = new Date(date);
      newDate.setDate(1); // Prevent month wrapping bug (e.g. 31 April = 1 May)
      newDate.setYear(dateItem.year);
      newDate.setMonth(dateItem.month);
      newDate.setDate(dateItem.date);
      if (this.attrs['on-selected']) {
        if (newDate && !isNaN(newDate.getTime())) {
          //Number.isNaN PhantomJs does not like this yet
          this.attrs['on-selected'](newDate, ev);
        } else {
          this.attrs['on-selected'](null, ev);
        }
      }
    }
  });
});