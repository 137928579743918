define('ember-google-charts/utils/render-material-chart', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = renderMaterialChart;

  var RSVP = _ember['default'].RSVP;

  function renderMaterialChart(data, options) {
    var _this = this;

    return new RSVP.Promise(function (resolve, reject) {
      var _window$google = window.google;
      var charts = _window$google.charts;
      var visualization = _window$google.visualization;

      var type = _ember['default'].String.capitalize(_this.get('type'));
      var dataTable = visualization.arrayToDataTable(data);

      var chart = _this.get('chart');

      if (!chart) {
        chart = new charts[type](_this.get('element'));
        visualization.events.addListener(chart, 'error', reject);
      }

      chart.draw(dataTable, charts[type].convertOptions(options));

      resolve(chart);
    });
  }
});