define("ember-x-tabs/helpers/eq", ["exports"], function (exports) {
  "use strict";

  exports.eq = eq;

  function eq(params /*, hash*/) {
    return params[0] === params[1];
  }

  exports["default"] = Ember.Helper.helper(eq);
});