define('ember-x-tabs/components/x-tab', ['exports', 'ember-x-tabs/templates/components/x-tab', 'ember-x-tabs/mixins/component-parent', 'ember-x-tabs/components/x-tab/pane'], function (exports, _emberXTabsTemplatesComponentsXTab, _emberXTabsMixinsComponentParent, _emberXTabsComponentsXTabPane) {
  'use strict';

  var computed = Ember.computed,
      A = Ember.A,
      getWithDefault = Ember.getWithDefault;

  exports['default'] = Ember.Component.extend(_emberXTabsMixinsComponentParent['default'], {
    layout: _emberXTabsTemplatesComponentsXTab['default'],
    classNames: ['tabs'],
    classNameBindings: ['tab-style', 'customClass'],
    // 'tab-style':'tabs-style-bar', // Default tab style

    childPanes: computed.filter('children', function (view) {
      return view instanceof _emberXTabsComponentsXTabPane['default'];
    }),

    activeId: computed.oneWay('childPanes.firstObject.elementId'),

    isActiveId: computed('activeId', {
      get: function get() {
        return getWithDefault(this, 'activeId', null);
      },
      set: function set(key, value) {
        return value;
      }
    }),

    navItems: computed('childPanes.@each.{elementId,title,icon}', function () {
      var items = A();
      this.get('childPanes').forEach(function (pane) {
        var item = pane.getProperties('elementId', 'title', 'icon');
        items.push(item);
      });
      return items;
    }),

    actions: {
      select: function select(id) {
        this.set('isActiveId', id);
      }
    }
  });
});