define('ember-cli-new-version/components/new-version-notifier/component', ['exports', 'ember', 'ember-getowner-polyfill', 'ember-cli-new-version/components/new-version-notifier/template'], function (exports, _ember, _emberGetownerPolyfill, _emberCliNewVersionComponentsNewVersionNotifierTemplate) {
  /*jshint esnext:true */

  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliNewVersionComponentsNewVersionNotifierTemplate['default'],
    updateInterval: 60000, // One Minute Default
    tagName: "div",
    versionFileName: "/VERSION.txt",
    versionFilePath: _ember['default'].computed.alias("versionFileName"),
    updateMessage: "This application has been updated from version {{oldVersion}} to {{newVersion}}. Please save any work, then refresh browser to see changes.",
    showReload: true,
    showReloadButton: _ember['default'].computed.alias("showReload"),
    reloadButtonText: "Reload",
    url: _ember['default'].computed('versionFileName', function () {
      var config = (0, _emberGetownerPolyfill['default'])(this).resolveRegistration('config:environment');
      var versionFileName = this.get('versionFileName');
      var baseUrl = config.rootURL || config.baseURL;

      if (!config || baseUrl === '/') {
        return versionFileName;
      }

      return baseUrl + versionFileName;
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      this.updateVersion();
    },
    updateVersion: function updateVersion() {
      var self = this;
      var t = setTimeout(function () {
        var currentTimeout = self.get('_timeout');
        if (currentTimeout) {
          clearTimeout(currentTimeout);
        }

        _ember['default'].$.ajax(self.get('url'), { cache: false }).then(function (res) {
          var currentVersion = self.get('version');
          var newVersion = res && res.trim();

          if (currentVersion && newVersion !== currentVersion) {
            var message = self.get("updateMessage").replace("{{oldVersion}}", currentVersion).replace("{{newVersion}}", newVersion);

            self.setProperties({
              message: message,
              lastVersion: currentVersion
            });
          }

          self.set('version', newVersion);
        }).always(function () {
          self.set('_timeout', setTimeout(function () {
            self.updateVersion();
          }, self.get('updateInterval')));
        });
      }, 10);
      self.set('_timeout', t);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('_timeout'));
    },
    actions: {
      reload: function reload() {
        location.reload();
      },

      close: function close() {
        this.set('message', undefined);
      }
    }
  });
});