define('ember-cli-notifications/services/notification-messages-service', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    exports['default'] = _ember['default'].ArrayProxy.extend({
        content: _ember['default'].A(),

        defaultClearDuration: 3200,

        addNotification: function addNotification(options) {

            // If no message is set, throw an error
            if (!options.message) {
                throw new Error("No notification message set");
            }

            var notification = _ember['default'].Object.create({
                message: options.message,
                type: options.type || 'info', // info, success, warning, error
                autoClear: options.autoClear || false,
                clearDuration: options.clearDuration || this.get('defaultClearDuration')
            });

            this.pushObject(notification);

            if (notification.autoClear) {
                this.setupAutoClear(notification);
            }

            return notification;
        },

        removeNotification: function removeNotification(notification) {
            if (!notification) {
                return;
            }
            notification.set('dismiss', true);
            // Delay removal from DOM for dismissal animation
            _ember['default'].run.later(this, (function () {
                this.removeObject(notification);
            }).bind(this), 500);
        },

        setupAutoClear: function setupAutoClear(notification) {
            _ember['default'].run.later(this, (function () {
                // Hasn't been closed manually
                if (this.indexOf(notification) >= 0) {
                    this.removeNotification(notification);
                }
            }).bind(this), notification.clearDuration);
        },

        clearAll: function clearAll() {
            this.set('content', _ember['default'].A());
        },

        setDefaultClearNotification: function setDefaultClearNotification(clearDuration) {
            if (_ember['default'].typeOf(clearDuration) !== 'number') {
                throw new Error('Clear duration must be a number');
            }

            this.set('defaultClearDuration', clearDuration);
        }
    });
});