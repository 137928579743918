define("ember-time-field/utils/mod", ["exports"], function (exports) {
  "use strict";

  exports["default"] = mod;

  // wrapping mod

  function mod(n, m) {
    return (n % m + m) % m;
  }
});