define('ember-simple-auth-extended/instance-initializers/setup-session-restoration', ['exports', 'ember-simple-auth-extended/utils/lookup'], function (exports, _emberSimpleAuthExtendedUtilsLookup) {
  'use strict';

  exports['default'] = setupSessionRestoration;

  function setupSessionRestoration(instance, name) {
    var applicationRoute = (0, _emberSimpleAuthExtendedUtilsLookup['default'])(instance, 'route:application');
    var session = (0, _emberSimpleAuthExtendedUtilsLookup['default'])(instance, 'session-' + name + ':main');
    var originalBeforeModel = applicationRoute.beforeModel;
    var applyOriginalBeforeModel = function applyOriginalBeforeModel() {
      return originalBeforeModel.apply(applicationRoute, arguments);
    };
    applicationRoute.reopen({
      beforeModel: function beforeModel() {
        var _arguments = arguments;

        return session.restore().then(function () {
          return applyOriginalBeforeModel.apply(undefined, _arguments);
        }, function () {
          return applyOriginalBeforeModel.apply(undefined, _arguments);
        });
      }
    });
  }
});