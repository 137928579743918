define('ember-cli-intercom/initializers/router', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.initialize = initialize;

  function initialize() {
    _ember['default'].Router.reopen({
      intercom: _ember['default'].inject.service(),

      notifyIntercom: _ember['default'].on('didTransition', function () {
        this.get('intercom').update();
      })
    });
  }

  exports['default'] = {
    name: 'router',
    initialize: initialize
  };
});