define('ember-x-tabs/mixins/component-parent', ['exports'], function (exports) {
    'use strict';

    var A = Ember.A,
        run = Ember.run;

    exports['default'] = Ember.Mixin.create({
        children: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('children', A());
        },
        registerChild: function registerChild(child) {
            run.schedule('sync', this, function () {
                this.get('children').addObject(child);
            });
        },
        removeChild: function removeChild(child) {
            this.get('children').removeObject(child);
        }
    });
});