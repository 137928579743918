define('ember-google-charts/components/google-chart', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var $ = _ember['default'].$;
  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;

  var isUsingEmber2 = _ember['default'].VERSION.match(/\b2\.\d+.\d+\b/g);

  exports['default'] = _ember['default'].Component.extend({

    /* Actions */

    chartDidRender: null,
    packagesDidLoad: null,

    /* Options */

    data: null,
    defaultOptions: {
      animation: {
        duration: 500,
        startup: false
      }
    },
    options: null,
    type: null,

    /* Properties */

    chart: null,
    classNameBindings: ['className'],
    classNames: ['google-chart'],
    googleCharts: _ember['default'].inject.service(),

    className: computed('type', function () {
      return this.get('type') + '-chart';
    }),

    /**
    The default options object with any properties specified
    in the options property overriding specific default options.
     @property mergedOptions
    @public
    */

    mergedOptions: computed('defaultOptions', 'options', function () {
      var defaultOptions = this.get('defaultOptions');
      var options = this.get('options');

      return $.extend({}, defaultOptions, options);
    }),

    /* Methods */

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupDependencies();

      /* If the Ember version is less than 2.0.0... */

      if (!isUsingEmber2) {
        this.addObserver('data', this, this._rerenderChart);
        this.addObserver('mergedOptions', this, this._rerenderChart);
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._rerenderChart();
    },

    setupDependencies: function setupDependencies() {
      var _this = this;

      var type = this.get('type');

      _ember['default'].warn('You did not specify a chart type', type);

      this.get('googleCharts').loadPackages().then(function () {
        _this.sendAction('packagesDidLoad');
        _this._renderChart();
      });
    },

    /**
    The method that components that extend this component should
    overwrite.
     @method renderChart
    @public
    */

    renderChart: function renderChart() {
      assert('You have created a chart type without a renderChart() method');
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._teardownChart();
    },

    _rerenderChart: function _rerenderChart() {
      if (this.get('chart') && this.get('data')) {
        this._renderChart();
      }
    },

    _renderChart: function _renderChart() {
      var _this2 = this;

      var data = this.get('data');
      var mergedOptions = this.get('mergedOptions');

      this.renderChart(data, mergedOptions).then(function (chart) {
        _this2.set('chart', chart);
        _this2.sendAction('chartDidRender', chart);
      });
    },

    _teardownChart: function _teardownChart() {
      var chart = this.get('chart');

      if (chart) {
        window.google.visualization.events.removeAllListeners(chart);
        chart.clearChart();
      }

      if (!isUsingEmber2) {
        this.removeObserver('data', this, this._rerenderChart);
        this.removeObserver('mergedOptions', this, this._rerenderChart);
      }
    }

  });
});